import React from 'react'
import {
  Breadcrumbs,
  Hero,
  Page,
  ServiceContact,
  Testing,
} from '@components'
import { buildLocalizedPath } from '@utils/functions'

const meta = {
  title: 'Laboratorijsko ispitivanje | FSB d.o.o.',
  description: 'Osmišljavamo vlastite sustave pasivne protupožarne zaštite te iste ispituje u LTM – laboratoriju za toplinska mjerenja. ',
  image: require('@assets/images/lab.jpg'),
}

const Homepage = ({ pageContext }) => (
  <Page
    isHomepage
    meta={meta}
    locale={pageContext.locale}
  >
    <Breadcrumbs
      links={[
        { link: buildLocalizedPath('/', pageContext.locale), name: 'header.link.home'},
        { link: buildLocalizedPath('/services/', pageContext.locale), name: 'header.link.services'},
        { link: buildLocalizedPath('/services/laboratory-tests/', pageContext.locale), name: 'service.4.hero.subtitle'},
      ]}
    />
    <Hero
      title="service.4.hero.title"
      subtitle="service.4.hero.subtitle"
      description="service.4.hero.description"
    />
    <Testing locale={pageContext.locale} />
    <ServiceContact id="ispitivanja" locale={pageContext.locale} />
  </Page>
)

export default Homepage